import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReelItem, ReelsListWrapper, ReelsViewportWrapper } from './ReelsStyledModules';
import ReelsMetaData, { ReelsVideoSection } from './ReelsMetaData';
import { ArrowCouple } from '../../../Carousel/components/Arrows';
// import { getReelCardConfigs } from '../utils';
import Modal from '@mui/material/Modal';
import classNames from 'classnames';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import useDisableScroll from '../../hooks/useDisableScroll';
import useDisableRootDoc from '../../hooks/useDisableRootDoc';
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import { getCurrentClass } from '../../../CardFrameCollection/utils';
import { getCartWrapperConfigs } from '../../../ProductCard/utils';
import { REEL_CONFIGS } from './data';

/**
 * Notes: 1. Scroll to selected reel is done from video reel component, which calculates the positioning & scrolls the
 *            reel to the center
 * 2. In Desktop, padding is put in scroll container horizontally to put the first & last videos at the center
 * */

// to hit when scroll has ended
let scrollTrackTimer = null;
let currentFocusedReelIndex = 0;

export function Reels({
  cardFrameConfig,
  oneGridCellGeometry,
  listPresentationConfig,
  isBuilder,
  isMobile,
  featuredReelConfigs,
  carouselIndicatorConfigs,
}) {
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();
  const reelsData = cardFrameConfig?.reelsData;
  const layoutName = listPresentationConfig?.layoutName;
  const videoRefs = useRef<Record<number, HTMLVideoElement>>({});
  const [scrollRef, setScrollRef] = useState<HTMLUListElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalScrolled, setModalScrolled] = useState(false); // This will be toggled
  const [currentFocusedReel, setCurrentFocusedReel] = useState<number>(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const [shouldMuteVideo, setShouldMuteVideo] = useState(false);

  const reels = reelsData?.filter((reelItem) => (reelItem.isVisible === undefined || reelItem.isVisible)) || [];

  const cardWrapperConfigs = getCartWrapperConfigs(listPresentationConfig);

  useDisableScroll({ isMobile, scrollRef });
  useDisableRootDoc({
    scrollRef,
    openModal,
    isMobile,
    setScreenHeight,
  });

  useEffect(() => {
    if (!openModal) {
      setCurrentFocusedReel(0);
      scrollTrackTimer = null;
      currentFocusedReelIndex = 0;
    }
    return () => {
      scrollTrackTimer = null;
      currentFocusedReelIndex = 0;
    };
  }, [currentFocusedReel, openModal]);

  // useEffect(() => {
  //   if (!isMobile) {
  //     console.log('currentFocusedReelIndex:  ', currentFocusedReelIndex);
  //     setCurrentFocusedReel(Math.ceil(currentFocusedReelIndex));
  //   }
  // }, [modalScrolled]);

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const setCallbackScrollRef = useCallback((node) => {
    if (!node) return;
    setScrollRef(node);
  }, []);

  const handleScroll = () => {
    if (!isMobile) {
      // const scrollPosition = scrollRef?.scrollLeft;
      // currentFocusedReelIndex = scrollPosition / REEL_WIDTH;
    }

    // This snaps the video component on the screen vertical edges, works when touch device is used
    if (isMobile) {
      if (scrollTrackTimer) {
        clearTimeout(scrollTrackTimer);
        scrollTrackTimer = null;
      }
      currentFocusedReelIndex = scrollRef?.scrollTop / screenHeight;

      scrollTrackTimer = setTimeout(() => {
        scrollTrackTimer = null;
        scrollRef?.scrollTo({
          top: screenHeight * Math.round(currentFocusedReelIndex),
        });
        setCurrentFocusedReel(Math.round(currentFocusedReelIndex));
      }, 130);
    } else {
      if (scrollTrackTimer) {
        clearTimeout(scrollTrackTimer);
        scrollTrackTimer = null;
      }
      // const intialHeadStart = window.innerWidth / 1 - 45;
      currentFocusedReelIndex =
        scrollRef?.scrollLeft /
        ((REEL_CONFIGS.FOCUSED_DIMENSIONS.width +
          REEL_CONFIGS.UNFOCUSED_DIMENSIONS.width) /
          2 +
          90);

      scrollTrackTimer = setTimeout(() => {
        setModalScrolled((v) => !v);
      }, 130);
      // scrollTrackTimer = setTimeout(() => {
      //   scrollTrackTimer = null;
      //   setCurrentFocusedReel(Math.round(currentFocusedReelIndex));
      // }, 130);
    }
  };

  function onArrowSlideClick(direction) {
    setCurrentFocusedReel((idx) =>
      (idx === reels?.length - 1 && direction === 'to-right') ||
      (idx === 0 && direction === 'to-left')
        ? idx
        : direction === 'to-right'
          ? idx + 1
          : idx - 1
    );
  }

  // For Modal
  function _RenderReelsModalBody() {
    return (
      <ReelsViewportWrapper isMobile={isMobile} mobileHeight={screenHeight}>
        <div
          className={classNames(
            'tw-fixed tw-z-[10000] tw-mx-auto tw-flex tw-w-full tw-max-w-[1400px] tw-justify-end',
            isMobile ? 'tw-right-[12px] tw-top-[12px]' : 'tw-right-[36px] tw-top-[36px]'
          )}
        >
          <div
            onClick={handleModalClose}
            className={classNames(
              'ic-cross-bg-black-lg wb-sprite  tw-rounded-3xl tw-shadow-[0px_4px_4px_#00000040]'
            )}
          />
        </div>
        <ArrowCouple
          isVisible={!isMobile && scrollRef?.scrollWidth > scrollRef?.clientWidth}
          className="tw-left-[50%] -tw-translate-x-1/2 md:tw-max-w-[1100px] md:!tw-px-[40px] lg:md:!tw-px-0" // lg:tw-max-w-[980px] xl:tw-max-w-[1080px] xxl:tw-max-w-[1180px]
          handlePrevClick={() => onArrowSlideClick('to-left')}
          handleNextClick={() => onArrowSlideClick('to-right')}
          imageProps={{
            bgColor: '#ffffff',
            bgOpacity: 1,
            arrowColor: '#000000',
            height: 48,
            width: 48,
          }}
        />
        <ReelsListWrapper
          columnGap={REEL_CONFIGS.COLUMN_GAP_DESKTOP_MODAL}
          maxWidth={!isMobile ? '800px' : '100vw'}
          ref={setCallbackScrollRef}
          className="no-scrollbar"
          onScroll={handleScroll}
          mobileHeight={screenHeight}
          onTouchStart={() => {
            // if (isMobile) {
            //   if (scrollTrackTimer) clearTimeout(scrollTrackTimer);
            // }
          }}
          onTouchEnd={() => {
            // if (isMobile) {
            //   scrollTrackTimer = setTimeout(() => {
            //     currentFocusedReelIndex = scrollRef?.scrollTop / screenHeight;
            //     scrollRef?.scrollTo({
            //       top: screenHeight * Math.round(currentFocusedReelIndex),
            //       behavior: 'instant',
            //     });
            //     setCurrentFocusedReel(Math.round(currentFocusedReelIndex));
            //     clearTimeout(scrollTrackTimer);
            //     scrollTrackTimer = null;
            //   }, 180);
            // }
          }}
        >
          {reels.map((reelItem, reelIdx) => {
            const isThisReelFocused = reelIdx === currentFocusedReel;
            const cardGeometry = isThisReelFocused
              ? REEL_CONFIGS.FOCUSED_DIMENSIONS
              : REEL_CONFIGS.UNFOCUSED_DIMENSIONS;

            return (
              <ReelItem
                key={reelIdx}
                {...cardGeometry}
                mobileHeight={screenHeight}
                borderRadius={globalStyle?.imageCards?.style?.borderRadius}
              >
                <ReelsVideoSection
                  isThisReelFocused={isThisReelFocused}
                  scrollContainerRef={scrollRef}
                  isModalOpen={openModal}
                  ref={videoRefs}
                  isBuilder={isBuilder}
                  fromModal
                  cardFrameConfig={cardFrameConfig}
                  reelIndex={reelIdx}
                  reelItem={reelItem}
                  isLastReel={reelIdx === reels?.length - 1}
                  oneGridCellGeometry={cardGeometry}
                  customStyle={isMobile ? { width: '100%', height: '100%' } : undefined}
                  onClick={() => setCurrentFocusedReel(reelIdx)}
                  modalScrolled={modalScrolled}
                  isMobile={isMobile}
                  shouldMuteVideo={shouldMuteVideo}
                  setShouldMuteVideo={setShouldMuteVideo}
                  callback={({ currentSelectedReelIdx }) => {
                    if (typeof currentSelectedReelIdx === 'number') {
                      setCurrentFocusedReel(currentSelectedReelIdx);
                    }
                  }}
                />
                {(!!isMobile || (!isMobile && isThisReelFocused)) && (
                  <ReelsMetaData
                    fromModal
                    reelItem={reelItem}
                    isMobile={isMobile}
                    cardFrameConfig={cardFrameConfig}
                  />
                )}
              </ReelItem>
            );
          })}
        </ReelsListWrapper>
      </ReelsViewportWrapper>
    );
  }

  // Shown as in page layout, no in modal
  const calculateReelLayoutDimension = () => {
    let initialWidth = oneGridCellGeometry?.width;
    if (cardWrapperConfigs.isCardLayout) {
      if (cardWrapperConfigs.isFullWidth) {
        initialWidth -= 2; // remove horizontal border width
      } else {
        // remove horizontal padding
        if (isMobile) initialWidth -= 20;
        else initialWidth -= 28;
      }
    }

    return { width: initialWidth, height: oneGridCellGeometry?.height };
  };

  return (
    <>
      {/** For layout cards */}
      {reels?.map((reelItem, reelIdx) => {
        // Mobile only: As this is on layout not in modal, column is set to one
        const isThisReelFocused =
          reelIdx * 2 ===
            Math.floor(carouselIndicatorConfigs.scrollPercentage / reels.length) - 2 ||
          (reelIdx + 1 === 1 &&
            Math.floor(carouselIndicatorConfigs.scrollPercentage / reels.length) - 2 ===
              1) ||
          (reelIdx + 1) * 2 + 1 ===
            Math.floor(carouselIndicatorConfigs.scrollPercentage / reels.length);

        return (
          <div
            className={classNames(
              'imgWrapperGlobalStyle globalImgStyleConsumer tw-relative tw-flex tw-flex-shrink-0 tw-snap-center tw-flex-col tw-overflow-hidden',
              cardWrapperConfigs.isCardLayout && 'tw-border-[1px] tw-border-solid',
              cardWrapperConfigs.isCardLayout &&
                !cardWrapperConfigs.isFullWidth &&
                (isMobile ? 'tw-px-[10px] tw-py-[10px]' : 'tw-px-[14px] tw-py-[14px]'),
              !isMobile &&
                cardFrameConfig?.hoverType === 'zoomInCard' &&
                contentAnimationStyle[getCurrentClass(cardFrameConfig?.hoverType)],
              featuredReelConfigs.expandFocusedReel &&
                isMobile &&
                isThisReelFocused &&
                'tw-scale-[1.1] tw-duration-500'
            )}
            key={reelIdx}
            style={{
              width: `${oneGridCellGeometry?.width}px`,
              borderColor: listPresentationConfig?.borderColor,
            }}
          >
            <ReelsVideoSection
              ref={videoRefs}
              isThisReelFocused={isThisReelFocused}
              isBuilder={isBuilder}
              reelItem={reelItem}
              isMobile={isMobile}
              isLastReel={reelItem === reels?.length - 1}
              reelIndex={reelIdx}
              isModalOpen={openModal}
              cardFrameConfig={cardFrameConfig}
              isMobileFocusPlayEnabled={featuredReelConfigs.expandFocusedReel}
              oneGridCellGeometry={calculateReelLayoutDimension()}
              onClick={() => {
                setCurrentFocusedReel(reelIdx);
                handleModalOpen();
              }}
            />
            <ReelsMetaData
              reelItem={reelItem}
              isMobile={isMobile}
              cardFrameConfig={cardFrameConfig}
              layoutName={layoutName}
              addPadding={
                cardWrapperConfigs.isCardLayout && cardWrapperConfigs.isFullWidth
              }
              listPresentationConfig={listPresentationConfig}
            />
          </div>
        );
      })}
      {/** This renders reels as an overlay */}
      {!isMobile ? (
        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {_RenderReelsModalBody()}
        </Modal>
      ) : (
        openModal && _RenderReelsModalBody()
      )}
    </>
  );
}
