import styled from 'styled-components';

export const ReelsViewportWrapper = styled.div<{
  isMobile?: boolean;
  mobileHeight?: number;
}>`
  max-width: 100vw;
  position: ${(props) => (props.isMobile ? 'fixed' : 'unset')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: ${(props) =>
    props.isMobile && props.mobileHeight ? `${props.mobileHeight}px` : '100%'};
  max-height: 100%;
  width: 100%;
  z-index: 9999;
  overflow: hidden;
  overscroll-behavior: none;
  background-color: #000000;

  @media screen and (min-width: 769px) {
    background-color: transparent;
    position: relative;
    /* max-width: 728px; */
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 0 40px;
    margin: 0 auto;
  }

  @media screen and (min-width: 968px) {
    /* max-width: 840px; */
  }

  @media screen and (min-width: 1286px) {
    max-width: 1200px;
  }
`;

export const ReelsListWrapper = styled.ul<{
  columnGap?: number;
  maxWidth?: string;
  mobileHeight?: number;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  overflow-y: auto;
  height: ${(props) => (props.mobileHeight ? `${props.mobileHeight}px` : '100%')};

  /* contain -> prevent accidentally going back or triggering other swipe events */
  overscroll-behavior: none;

  /* hide scroll bar */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* momentum scroll on iOS */
  -webkit-overflow-scrolling: touch;

  /* disbable vertical scroll for touch */
  touch-action: pan-y pan-x;

  /* scroll snapping */
  scroll-snap-type: y mandatory;

  @media screen and (min-width: 769px) {
    touch-action: none;
    width: 540px;
    max-width: 700px;
    gap: ${(props) => props.columnGap}px;
    scroll-snap-type: x mandatory;
    padding: 0 calc(250px - 45px); /** Removed half of the gap */
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    max-height: 100vh;
  }

  @media screen and (min-width: 968px) {
    width: 700px;
    max-width: 800px;
    gap: ${(props) => props.columnGap}px;
    padding: 0 calc(350px - 45px); /** Removed half of the gap */
    margin: 0 auto;
  }

  @media screen and (min-width: 1068px) {
    width: unset;
    max-width: 1000px;
    padding: 0 calc(360px - 45px); /** Removed half of the gap */
  }

  /* @media screen and (min-width: 968px) {
    width: 800px;
    padding: 0 calc(400px - 45px);
  }

  @media screen and (min-width: 1024px) {
    width: 900px;
    padding: 0 calc(450px - 45px);
  }

  @media screen and (min-width: 1280px) {
    width: 1200px;
    padding: 0 calc(600px - 45px);
  } */
`;

/* height: ${(props) => `${props.mobileHeight}px` || '100vh'}; */
export const ReelItem = styled.li<{
  width: number;
  height: number;
  mobileHeight?: number;
  borderRadius?: any;
}>`
  overscroll-behavior: none;
  width: 100vw;
  height: ${(props) => `${props.mobileHeight}px` || '100%'};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: #000000;
  transition: scale 500ms linear;
  position: relative;

  /* scroll snapping */
  scroll-snap-align: center;

  /* maybe this will work in the future 🤷‍♂️ */
  scroll-snap-stop: always;

  @media screen and (min-width: 768px) {
    overflow: hidden;
    width: ${(props) => `${props.width}px` || '280px'};
    height: ${(props) => `${props.height}px` || '530px'};
    border-radius: ${(props) => props.borderRadius};

    /* scroll snapping */
    scroll-snap-align: center;
  }
`;
