export const REEL_CONFIGS = {
  FOCUSED_DIMENSIONS: {
    width: 317,
    height: 564,
  },
  UNFOCUSED_DIMENSIONS: {
    width: 224,
    height: 400,
  },
  COLUMN_GAP_DESKTOP_MODAL: 90,
};
