import { useEffect } from 'react';

export function useLoadVisibleReel({ videoRef, reelIndex, setLoadVideo, loadVideo }) {
  useEffect(() => {
    if (videoRef[reelIndex]) {
      const loadMoreCallback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setLoadVideo(true);
            observer.unobserve(entry.target);
          }
        });
      };

      let observer;
      if (!loadVideo) {
        observer = new IntersectionObserver(loadMoreCallback, {
          root: null,
          rootMargin: '0px',
          threshold: 0.01,
        });
        observer.observe(videoRef[reelIndex]);
      } else {
        observer?.unobserve(videoRef[reelIndex]);
      }
      return () => {
        videoRef[reelIndex] && observer?.unobserve(videoRef[reelIndex]);
      };
    }
  }, []);

  return null;
}
