import { useEffect } from 'react';

export default function useDisableRootDoc({
  scrollRef,
  openModal,
  isMobile,
  setScreenHeight,
  disableDoc = false,
}) {
  useEffect(() => {
    if (isMobile) {
      setScreenHeight(window.innerHeight);
      const htmlElement = document.getElementsByTagName('html')[0];
      const bodyElement = document.getElementsByTagName('body')[0];

      const onResize = () => {
        setScreenHeight(window.innerHeight);
      };

      const modifyHtmlCustomStyles = (addCustomStyles) => {
        if (!htmlElement || !bodyElement) return;
        if (!addCustomStyles) {
          htmlElement.style.overflowY = '';
          htmlElement.style.position = '';
          htmlElement.style.width = '';
          htmlElement.style.height = '';
          htmlElement.style.overscrollBehaviorY = '';
          bodyElement.style.overflowY = '';
          bodyElement.style.width = '';
          bodyElement.style.overscrollBehaviorY = '';
          bodyElement.style.height = '';
        } else {
          htmlElement.style.overflowY = 'hidden';
          htmlElement.style.position = 'fixed';
          htmlElement.style.width = '100%';
          htmlElement.style.overscrollBehaviorY = 'none';
          htmlElement.style.height = '100%';
          htmlElement.style.maxHeight = '100%';
          bodyElement.style.overflowY = 'hidden';
          bodyElement.style.width = '100%';
          bodyElement.style.overscrollBehaviorY = 'none';
          bodyElement.style.height = '100%';
          bodyElement.style.maxHeight = '100%';
        }
      };

      if (openModal) {
        disableDoc && modifyHtmlCustomStyles(true);
        window.addEventListener('resize', onResize);
      }
      if (!openModal) {
        disableDoc && modifyHtmlCustomStyles(false);
        window.removeEventListener('resize', onResize);
      }

      return () => {
        disableDoc && modifyHtmlCustomStyles(false);
      };
    }
  }, [scrollRef, openModal, isMobile]);
  return null;
}
